import React, { useCallback, useEffect, useState, useMemo, useRef, useContext, MouseEvent } from "react";
import { DndContext, DragOverlay, useSensor, useSensors, MouseSensor, pointerWithin, useDroppable, DragStartEvent, DragEndEvent } from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { DeliveryComponent, CurrentTimeContext } from "./delivery";

import HeatMap from "./heatmap";
import * as Other from "./other";
import { Dashboard, Driver, Delivery, DeliveryGroup, ApiClientInterface } from "./model";

interface DispatchDashboardProps {
  apiClient: ApiClientInterface;
  currentAdminId: number;
}

interface TabNotification {
  order: number;
  driver: boolean;
}

const defaultTabNotification = {
  order: 0,
  driver: false
}

const DispatchDashboard = ({ apiClient, currentAdminId } : DispatchDashboardProps) => {
  const [ dashboard, setDashboard ] = useState(Dashboard.emptyDashboard());
  const [ connected, setConnected ] = useState(false);
  const [ now, setNow ] = useState(new Date());
  const [ busySection, setBusySection ] = useState(false);
  const [ draggedNode, setDraggedNode ] = useState<{ type: string; id: number } | null>(null);

  const originalDocumentTitle = useRef(document.title);
  const [ previousOrderNotificationAt, setPreviousOrderNotificationAt ] = useState(0);
  const [ previousDriverNotificationAt, setPreviousDriverNotificationAt ] = useState(0);
  const [ tabNotification, setTabNotification ] = useState<TabNotification>(defaultTabNotification);

  useEffect(() => {
    const deliverySorter = (a: Delivery, b: Delivery) : number => {
      // Sort order is pickupTime, pickupZoneId, storeName, suburbName, id
      if (a.pickupTimeIso8601 < b.pickupTimeIso8601)
        return -1;
      else if (a.pickupTimeIso8601 > b.pickupTimeIso8601)
        return 1;
      // TOOD: PickupZoneID
      else if (a.storeName < b.storeName)
        return -1;
      else if (a.storeName > b.storeName)
        return 1;
      else if (a.suburb < b.suburb)
        return -1;
      else if (a.suburb > b.suburb)
        return 1;
      else
        return (a.id - b.id);
    }

    const handleDashboardUpdated = (newDashboard: Dashboard) => {
      newDashboard.deliveries.sort(deliverySorter);

      setDashboard(newDashboard);
    }

    const handleDashboardConnected = (connected: boolean) => {
      setConnected(connected);
    }

    const resetDocumentTitle = () => {
      setTabNotification(defaultTabNotification);
    }

    const intervalTimer = window.setInterval(() => setNow(new Date()), 5000);

    apiClient.dashboardUpdated.addListener(handleDashboardUpdated);
    apiClient.dashboardConnected.addListener(handleDashboardConnected);
    apiClient.start();

    window.addEventListener("focus", resetDocumentTitle);

    return () => {
      clearInterval(intervalTimer);

      window.removeEventListener("focus", resetDocumentTitle);

      apiClient.stop();
      apiClient.dashboardConnected.removeListener(handleDashboardConnected);
      apiClient.dashboardUpdated.removeListener(handleDashboardUpdated);
    };
  }, [ apiClient ]);

  const descriptiveDriverList = (title : string, drivers: Driver[], showBusySectionToggle = false) : React.JSX.Element => {
    if (drivers.length == 0) {
      return <></>;
    }

    const handleBusySectionToggle = () => {
      setBusySection(!busySection);
    }

    let busySectionToggle = <></>;
    // if both busy sections are visible & it's the first busy section
    if (showBusySectionToggle && ((busySection && title == "Busy (picking up)") || (!busySection && title == "Busy (dropping off)"))) {
      busySectionToggle = <button className="button is-small is-text mr-2 has-tooltip-top has-tooltip-arrow" data-tooltip="Toggle busy sections" onClick={handleBusySectionToggle}>
        <span className="icon is-small">
          <i className="fa-solid fa-arrow-up-arrow-down"></i>
        </span>
      </button>;
    }

    const dashSeparator = title.startsWith("Busy")
      ? <span className="dash-separator"></span>
      : <></>;

    return <>
      <div className="is-flex mb-4 is-align-items-center">
        {busySectionToggle}
        <span className="is-size-5 is-flex-shrink-0">{title}</span>
        <span className="tag is-rounded is-white is-light ml-2">{drivers.length}</span>
        {dashSeparator}
      </div>

      <Other.DriverList
        dashboard={dashboard}
        drivers={drivers}
        apiClient={apiClient}
      />
    </>;
  }

  const hasCurfewHighlight = useMemo(() => {
    const hour = now.getHours();
    if (hour > 20 || hour < 5 || (hour == 20 && now.getMinutes() >= 45)) {
      return true;
    }
    return false;
  }, [now]);


  const handleDragStart = (event: DragStartEvent) => {
    const dragTarget = event.active.data.current;

    if (!dragTarget) return;

    dragTarget.unsetErrorTimer();
    setDraggedNode({ type: dragTarget.type, id: dragTarget.id });
  }

  const handleDragCancel = () => {
    setDraggedNode(null);
  }

  const handleDragEnd = useCallback(async (event: DragEndEvent) => {
    setDraggedNode(null);

    if (event.over === null || !document.hasFocus()) return;

    const dragTarget = event.active.data.current;
    const dropTarget = event.over.data.current;

    if (!dragTarget || !dropTarget || Object.keys(dragTarget).length == 0 || Object.keys(dropTarget).length == 0) return;

    if (dragTarget.type == "delivery") {
      if (dropTarget.type == "group") {
        dragTarget.handleDropping();
        await apiClient.allocateDeliveryToGroup(dragTarget.id, dropTarget.id);
      }
      else if (dropTarget.type == "driver") {
        dragTarget.handleDropping();
        await dashboard.handleAllocateDeliveryToDriver(dragTarget.id, dropTarget.id, apiClient)
          .then(result => {
            if (result === false) {
              dragTarget.handleDragFailure();
            }
          });
      }
    }

    else if (dragTarget.type == "group") {
      if (dropTarget.type == "group") {
        // Because the group's own container is a valid droppable, & merging a group to itself is a valid action
        // specifically check & ignore that case (there is also some CSS to prevent highlightling the container in this case)
        if (dragTarget.id == dropTarget.id) return;

        dragTarget.handleDropping();
        await apiClient.mergeGroups(dragTarget.id, dropTarget.id);
      }
      else if (dropTarget.type == "driver") {
        dragTarget.handleDropping();
        await dashboard.handleAllocateGroupToDriver(dragTarget.id, dropTarget.id, apiClient)
          .then(result => {
            if (result === false) {
              dragTarget.handleDragFailure();
            }
          });
      }
    }
  }, [ dashboard, apiClient ]);

  const sensors = useSensors(
    useSensor(SmartMouseSensor, { activationConstraint: { distance: 8 } })
  );

  const draggedNodeClone = useMemo(() => {
    if (draggedNode?.type == "delivery") {
      const delivery = dashboard.deliveries.find(d => d.id == draggedNode.id);

      if (delivery) {
        return <div className="card is-card-dashboard">
          <div className="card-content p-0">
            <DeliveryComponent
              delivery={delivery}
              defaultExpand={true}
              deliveringNow={false}
              dashboard={dashboard}
              apiClient={apiClient}
            />
          </div>
        </div>;
      }
    }

    else if (draggedNode?.type == "group") {
      const group = dashboard.groups.find(g => g.id == draggedNode.id);
      const deliveries = dashboard.deliveriesForGroup(draggedNode.id);

      if (group && deliveries.length > 0) {
        return <Other.DeliveryGroupComponent
          group={group}
          deliveries={deliveries}
          dashboard={dashboard}
          apiClient={apiClient}
        />;
      }
    }

    return <></>;
  }, [draggedNode, dashboard, apiClient]);


  const allActiveGroupIds = dashboard.deliveries.map(o => o.groupId);
  const activeDeliveryGroups = dashboard.groups.filter(og => allActiveGroupIds.includes(og.id));
  const anyGroupedDeliveries = activeDeliveryGroups.length > 0;
  const deliveryGroupList = anyGroupedDeliveries ? <DeliveryGroupList
    dashboard={dashboard}
    deliveriesByGroup={dashboard.deliveriesByGroup()}
    apiClient={apiClient}
  /> : <></>;

  const busyPickingUp = dashboard.driversBusyPickingUp();
  const busyDroppingOff = dashboard.driversBusyDroppingOff();
  const showBusySectionToggle = !!(busyPickingUp.length && busyDroppingOff.length);

  const driversAwaitingDeliveriesArr = dashboard.driversAwaitingDeliveries();
  const driversAwaitingDeliveries = descriptiveDriverList("Waiting for orders", driversAwaitingDeliveriesArr);
  const driversIdle = descriptiveDriverList("Idle", dashboard.driversIdle());
  const driversBusyPickingUp = descriptiveDriverList("Busy (picking up)", busyPickingUp, showBusySectionToggle);
  const driversBusyDroppingOff = descriptiveDriverList("Busy (dropping off)", busyDroppingOff, showBusySectionToggle);
  const driversBusyFirst = busySection ? driversBusyPickingUp : driversBusyDroppingOff;
  const driversBusySecond = !busySection ? driversBusyPickingUp : driversBusyDroppingOff;
  const driversRostered = descriptiveDriverList("Rostered but not active", dashboard.driversRostered());
  const driversRosteredToStartSoon = descriptiveDriverList("Rostered to start soon", dashboard.driversRosteredToStartSoon());

  const unallocatedCurrent = dashboard.unallocatedCurrent();
  const unallocatedFuture = dashboard.unallocatedFuture();


  useEffect(() => {
    setPreviousOrderNotificationAt(unallocatedCurrent.length);

    if (document.hasFocus()) return;

    // Update tab title
    //  on any change to the incoming order count
    if (unallocatedCurrent.length != previousOrderNotificationAt)
      setTabNotification(prevState => ( { ...prevState, order: unallocatedCurrent.length } ));

    // Play a sound
    if (unallocatedCurrent.length > previousOrderNotificationAt) {
      const soundEvent = new CustomEvent("soundEvent");
      window.dispatchEvent(soundEvent);
    }
  }, [unallocatedCurrent.length, previousOrderNotificationAt]);

  useEffect(() => {
    setPreviousDriverNotificationAt(driversAwaitingDeliveriesArr.length);

    if (document.hasFocus()) return;

    // Update tab title
    //  when the number of drivers awaiting deliveries goes from 0 -> 1+
    if (previousDriverNotificationAt == 0 && driversAwaitingDeliveriesArr.length > 0)
      setTabNotification(prevState => ( { ...prevState, driver: true } ));
  }, [driversAwaitingDeliveriesArr.length, previousDriverNotificationAt]);

  // Handle updating the tab title, with multiple independent notifications
  useEffect(() => {
    const driverNotification = tabNotification.driver ? "! " : "";
    const orderNotification = tabNotification.order > 0 ? `(${tabNotification.order}) ` : "";
    document.title = driverNotification + orderNotification + originalDocumentTitle.current;
  }, [tabNotification]);


  return (
    <CurrentTimeContext.Provider value={now}>
      <DndContext sensors={sensors} collisionDetection={pointerWithin} modifiers={[ restrictToFirstScrollableAncestor ]} onDragStart={handleDragStart} onDragCancel={handleDragCancel} onDragEnd={handleDragEnd}>
        <div className={"dispatch-dashboard has-background-grey-lighter is-size-7 " + (connected ? '' : 'is-disconnected') + (hasCurfewHighlight ? " highlight-restricted" : "")}>
          <DragOverlay className="drag-overlay" dropAnimation={null} zIndex={99}>{draggedNodeClone}</DragOverlay>
          <HeatMap slots={dashboard.heatMap()} apiClient={apiClient} />

          <div className="columns px-3 mx-0">
            <div className="column is-one-quarter">
              <div className="is-flex mb-4 is-align-items-center">
                <span className="is-size-5">Incoming orders</span>
                <span className="tag is-rounded is-white is-light ml-2">{unallocatedCurrent.length}</span>
              </div>

              <Other.UnallocatedDeliveryList
                unallocatedDeliveries={unallocatedCurrent}
                dashboard={dashboard}
                apiClient={apiClient}
              />

              <div className="is-flex mb-4 is-align-items-center">
                <span className="is-size-5">Future orders</span>
                <span className="tag is-rounded is-white is-light ml-2">{unallocatedFuture.length}</span>
              </div>

              <div className="is-half-opacity">
                <Other.UnallocatedDeliveryList
                  unallocatedDeliveries={unallocatedFuture}
                  dashboard={dashboard}
                  apiClient={apiClient}
                />
              </div>
            </div>

            <div className="column">
              <StatusBar
                dashboard={dashboard}
                apiClient={apiClient}
                currentAdminId={currentAdminId}
              />
              {deliveryGroupList}

              {driversAwaitingDeliveries}
              {driversIdle}
              {driversBusyFirst}
              {driversBusySecond}
              {driversRostered}
              {driversRosteredToStartSoon}
            </div>
          </div>
        </div>
      </DndContext>
    </CurrentTimeContext.Provider>
  );
}

// This prevents dragging on elements & their children, via data-nodnd="true" attr
// https://github.com/clauderic/dnd-kit/issues/477
class SmartMouseSensor extends MouseSensor {
  static activators = [{
    eventName: "onMouseDown",
    handler: ({ nativeEvent: event }: MouseEvent) => {
      let cur: HTMLElement | null = event.target as HTMLElement;

      while (cur) {
        if (cur.dataset?.nodnd) {
          return false;
        }
        cur = cur.parentElement;
      }

      return true;
    }
  }] as typeof MouseSensor.activators;
}

const DroppableGroupNode = ({
  group,
  groupEl,
}: {
  group: DeliveryGroup;
  groupEl: any;
}): React.JSX.Element => {
  const { isOver, setNodeRef } = useDroppable({
    id: `group:${group.id}`,
    data: {
      id: group.id,
      type: "group"
    }
  });

  const classes = isOver ? "has-draggable-hover" : "";

  return (
    <div className={`column is-one-third ${classes}`} ref={setNodeRef}>
      {groupEl}
    </div>
  );
}

interface DeliveryGroupListProps {
  dashboard: Dashboard;
  deliveriesByGroup: Map<number, Delivery[]>;
  apiClient: ApiClientInterface;
}

const DeliveryGroupList = ({ dashboard, deliveriesByGroup, apiClient } : DeliveryGroupListProps) => {
  const handleRegroups = useCallback(async () => {
    await apiClient.compactGroups();
  }, [ apiClient ]);

  const allActiveGroupIds = Array.from(deliveriesByGroup.keys());
  const activeDeliveryGroups = dashboard.groups.filter(og => allActiveGroupIds.includes(og.id));

  const totalActiveGroups = activeDeliveryGroups.length;
  let columnsEls = [];

  for (let i = 0; i < dashboard.groups.length; i+= 3) {
    const groups = dashboard.groups.slice(i, i+3);
    const deliveriesCol = (idx: number) => (deliveriesByGroup.get(groups[idx].id) ?? []).length;
    const rowHasAnyDeliveries = (deliveriesCol(0) + deliveriesCol(1) + deliveriesCol(2)) > 0;

    if (rowHasAnyDeliveries) {
      const groupNodes = groups.map((group) => {
        const deliveries = deliveriesByGroup.get(group.id) ?? [];
        const groupEl = deliveries.length > 0 ?
          <Other.DeliveryGroupComponent
            key={group.id}
            dashboard={dashboard}
            group={group}
            deliveries={deliveries}
            apiClient={apiClient}
          /> : <></>;

        return (
          <DroppableGroupNode
            group={group}
            groupEl={groupEl}
            key={group.id}
          />
        );
      });

      columnsEls.push(
        <div className="mb-5" key={i}>
          <div className="columns is-multiline is-variable is-2">
            {groupNodes}
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <div className="is-flex mb-4 is-align-items-center">
        <span className="is-size-5">Groups</span>
        <span className="tag is-rounded is-white is-light ml-2">{totalActiveGroups}</span>
        <button className="button is-small is-text ml-2 has-tooltip-bottom has-tooltip-arrow" data-tooltip="Tidy up" onClick={handleRegroups}>
          <span className="icon is-small">
            <i className="fas fa-th" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      {columnsEls}
    </>
  );
}

interface StatusBarProps {
  dashboard: Dashboard;
  apiClient: ApiClientInterface;
  currentAdminId: number;
}

const StatusBar = ({ dashboard, apiClient, currentAdminId } : StatusBarProps) => {
  const currentTime = useContext(CurrentTimeContext);

  const thirtyMinutesOverdue = (delivery: Delivery) => {
    return pickupOverdueBy(delivery, 30) || deliveryOverdueBy(delivery, 30);
  }

  const pickupOverdueBy = (delivery: Delivery, minutes: number) => {
    return !delivery.pickedUpAt && currentTime.getTime() - delivery.pickupTimeIso8601.getTime() > minutes*60*1000;
  }

  const deliveryOverdueBy = (delivery: Delivery, minutes: number) => {
    return currentTime.getTime() - delivery.dropoffTimeIso8601.getTime() > minutes*60*1000;
  }


  const deliveriesUnacknowledgedByDriversCount = dashboard.deliveries.filter(delivery => delivery.driverReviewing()).length;
  const driversCanDeliverAgeRestrictedCount = dashboard.driversActive().filter(driver => driver.alcoholDeliveryAllowed).length;
  const deliveriesThirtyMinutesOverdueCount = dashboard.deliveries.filter(delivery => thirtyMinutesOverdue(delivery)).length;


  const takeDashButton = () => {
    if (currentAdminId == dashboard.dispatcherId) {
      return;
    }

    const handleUpdateDispatcher = async () => {
      await apiClient.updateDispatcher();
    };

    return <button className="button tag" onClick={handleUpdateDispatcher}>
      <span className="icon is-small mr-1">
        <i className="fa-solid fa-share" aria-hidden="true"></i>
      </span>
      Take dash
    </button>;
  }

  const activeDispatcher = () => {
    if (currentAdminId == dashboard.dispatcherId) {
      return;
    }

    if (dashboard.dispatcherId === null) {
      return <span className="tag no-dispatcher-warning">No dispatcher</span>;
    }

    return <span className="tag has-background-black has-text-white">Dispatcher: {dashboard.dispatcherName}</span>;
  }

  const deliveriesUnacknowledgedByDrivers = deliveriesUnacknowledgedByDriversCount > 0
    ? <span className="tag unacknowledged-deliveries-count" title="Number of orders that are unacknowledged by drivers">{deliveriesUnacknowledgedByDriversCount} order{deliveriesUnacknowledgedByDriversCount == 1 ? "" : "s"} unacknowledged</span>
    : <></>;

  const driversCanDeliverAgeRestricted = driversCanDeliverAgeRestrictedCount < 2
    ? <span className="tag" title="Number of drivers that can deliver age restricted goods">{driversCanDeliverAgeRestrictedCount} R18 driver{driversCanDeliverAgeRestrictedCount == 1 ? "" : "s"}</span>
    : <></>;

  const deliveriesThirtyMinutesOverdue = deliveriesThirtyMinutesOverdueCount > 0
    ? <span className="tag thirty-minutes-overdue-count" title="Number of orders that are thirty minutes overdue">{deliveriesThirtyMinutesOverdueCount} order{deliveriesThirtyMinutesOverdueCount == 1 ? "" : "s"} overdue</span>
    : <></>;


  return (
    <div className="status-bar is-flex is-flex-wrap-wrap is-gap-1.5 mb-3">
      {takeDashButton()}
      {activeDispatcher()}
      {deliveriesUnacknowledgedByDrivers}
      {driversCanDeliverAgeRestricted}
      {deliveriesThirtyMinutesOverdue}
    </div>
  );
}

export default DispatchDashboard;
