import { Controller } from "@hotwired/stimulus";
import React from "react";
import { createRoot } from "react-dom/client";

import { AutocompleteTagSearch } from "./autocomplete_tag_search_component";

export default class extends Controller {
  static targets = ["hiddenInput"];

  declare hiddenInputTarget : HTMLInputElement;
  async connect() {
    const autocompleteContainer = document.getElementById("autocomplete");
    if (!autocompleteContainer) {
      throw "Unable to find container with id #autocomplete";
    }

    const tagsJson: string | null = this.data.get("tags-json");
    if (tagsJson == null){
      console.error("Can't setup autocomplete without the tags");

      return;
    }
    const allTags = JSON.parse(tagsJson);

    const hiddenInputTarget = this.hiddenInputTarget;
    const initialTagsJson: string | null = this.data.get("initial-tags-json");
    const initialTags = initialTagsJson ? JSON.parse(initialTagsJson) : [];

    const root = createRoot(autocompleteContainer);
    root.render(
      React.createElement(AutocompleteTagSearch, {
        formHiddenInput: hiddenInputTarget,
        allTags: allTags,
        initialTags: initialTags,
      })
    );
  }
}
