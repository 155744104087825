import { Controller } from "@hotwired/stimulus";
import { createRoot } from "react-dom/client";
import React from "react";
import DispatchDashboard from "src/admin/dispatch/dashboard";
import DispatchApiClient from "src/admin/dispatch/dispatch_api_client";

export default class DispatchController extends Controller {
  static targets = ["dashboard"];

  declare dashboardTarget : HTMLDivElement;
  declare apiClient : DispatchApiClient;

  connect() {
    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;
    if (metaElement === undefined) {
      throw "Missing CSRF meta tag";
    }
    const csrfToken = metaElement.content;
    const areaIdStr = this.data.get("area-id");

    if (csrfToken === null || areaIdStr === null) {
      throw "Missing config";
    }

    const areaId = parseInt(areaIdStr);
    this.apiClient = new DispatchApiClient(areaId, csrfToken);

    const currentAdminIdStr = this.data.get("current-admin-id");
    const currentAdminId = parseInt(currentAdminIdStr || "0");

    const root = createRoot(this.dashboardTarget);
    root.render(React.createElement(DispatchDashboard, {
      apiClient: this.apiClient,
      currentAdminId
    }));

    const reloadInSeconds = this.data.get("reload-in-seconds");
    if (reloadInSeconds != undefined) {
      window.setTimeout(() => window.location.reload(), parseInt(reloadInSeconds)*1000);
    }
  }

  disconnect() {
    this.apiClient.stop();
  }
}
