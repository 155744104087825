import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { createRoot, Root } from 'react-dom/client';

import { autocomplete } from '@algolia/autocomplete-js';
import { BaseTag, createTagsPlugin } from "@algolia/autocomplete-plugin-tags";
import { BaseItem, OnSelectParams, AutocompleteState } from "@algolia/autocomplete-core";

interface Item extends BaseItem, BaseTag {
  label: string;
  value: string;
}

interface TagCategory {
  category: string;
  tags: Item[];
}

function TagItem({ label } : { label: string } & React.JSX.IntrinsicAttributes) {
  return (
    <div className="aa-Tag tag p-2 mt-1">
      <span className="aa-TagLabel">{label}</span>
    </div>
  );
}

export function AutocompleteTagSearch(props: { formHiddenInput: HTMLInputElement; allTags: TagCategory[]; initialTags: Item[] }) {
  const containerRef = useRef(null);
  const panelRootRef = useRef<Root | null>(null);
  const rootRef = useRef<HTMLElement | null>(null);

  const tagsPlugin = createTagsPlugin<Item, Item>({
    getTagsSubscribers() {
      return props.allTags.map( (element: { category: string }) => {
        return {
          sourceId: element.category,
          getTag({ item }: {item: Item}) {
            return item;
          },
        };
      });
    },

    async onChange({ tags, setIsOpen }) {
      const currentTagIds = tags.map(tag => { return tag.value }).toString();
      props.formHiddenInput.value = currentTagIds;

      requestAnimationFrame(() => {
        const oldTagsContainer = document.querySelector('.aa-Tags');
        const tagsContainer = document.createElement('div');

        setIsOpen(false);

        tagsContainer.classList.add('aa-Tags');

        const root = createRoot(tagsContainer);
        root.render(
          <div className="aa-TagsList is-flex-wrap-wrap tags are-medium">
            {tags.map((tag) => (
              <TagItem key={tag.value} {...tag} />
            ))}
          </div>
        );

        const container = document.querySelector('#autocomplete');
        if (container === null) {
          console.error("Can't setup autocomplete without the tags wrapper container");
          return;
        }

        if (oldTagsContainer) {
          container.removeChild(oldTagsContainer);
        }
        container.appendChild(tagsContainer);
      });
    },
  });

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      detachedMediaQuery: 'none',
      openOnFocus: true,
      plugins: [tagsPlugin],
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      renderer: { createElement, Fragment, render: () => {} },
      getSources() {
        return props.allTags.map((element: TagCategory) => {
          return {
            sourceId: element.category,
            getItems({ query, state }: {query: string; state: AutocompleteState<Item>}) {
              const items = element.tags.map(item => {
                const tagsPlugin = state.context.tagsPlugin as { tags: Item[] };
                if (!(tagsPlugin.tags.some((tag: Item) => item.value == tag.value)))
                  return { label: item.label, value: item.value };
                else return undefined;
              })

              return items.filter(item =>
                item && item.label.toLowerCase().includes(query.toLowerCase())
              ) as Item[];
            },
            templates: {
              header() {
                return (
                  <>
                    <span className="aa-SourceHeaderTitle">{element.category}</span>
                    <div className="aa-SourceHeaderLine" />
                  </>
                );
              },
              item({ item }: {item: Item}) {
                return item.label;
              },
            },
            onSelect({ setQuery } : OnSelectParams<Item>) {
              setQuery(''); // Clears the search box
            },
          }
        });
      },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }

        panelRootRef.current.render(children);
      },
    });

    tagsPlugin.data?.setTags(props.initialTags);

    return () => {
      search.destroy();
    };
  }, [props.allTags, props.initialTags, tagsPlugin]);

  return <div id="autocomplete-tag-search-container" ref={containerRef} />;
}
