import { Controller } from "@hotwired/stimulus";
import { GoogleAutocompleteResolver, ResolvedAddress } from "src/google_autocomplete_resolver";

interface AddressParams extends ResolvedAddress {
  street_address?: string;
}

export default class extends Controller {
  static targets = [
    "form", "field",
    "addressErrorText", "addressErrorButton", "modal", "modalContent", "notServicedSection",
    "addressInputSection", "changeAddressButton", "applyButton"
  ];

  declare fieldTarget : HTMLInputElement;
  declare formTarget : HTMLFormElement;
  declare addressErrorTextTarget: HTMLDivElement;
  declare addressErrorButtonTarget: HTMLElement;
  declare hasAddressErrorButtonTarget: boolean;
  declare modalTarget: HTMLDivElement;
  declare modalContentTarget: HTMLParagraphElement;
  declare notServicedSectionTarget: HTMLDivElement;
  declare addressInputSectionTarget: HTMLDivElement;
  declare changeAddressButtonTarget: HTMLButtonElement;
  declare hasChangeAddressButtonTarget: boolean;
  declare applyButtonTarget: HTMLButtonElement;
  declare hasApplyButtonTarget: boolean;

  autocomplete?: google.maps.places.Autocomplete;
  originalInput?: string;
  specificAddressChosen?: boolean;

  async connect(): Promise<void> {
    await google.maps.importLibrary("places");
    this.initAutocomplete();
  }

  initAutocomplete(): void {
    this.autocomplete = GoogleAutocompleteResolver.defaultAutoComplete(this.fieldTarget);
    this.autocomplete.addListener("place_changed", this.placeChanged.bind(this));

    this.fieldTarget.oninput = () => { this.fieldTarget.setCustomValidity(''); };
    this.fieldTarget.oninvalid = () => { this.fieldTarget.setCustomValidity('Please enter an address'); };
  }

  async placeChanged(): Promise<void> {
    if (this.fieldTarget.value == "") return;

    // If the user hits enter on a garbage address with no autocomplete results, we get a placeChanged but getPlace
    // returns a place that has no details filled in except the entered text. We can wait for resolveAutocomplete/
    // GoogleAutocompleteResolver#resolveFromAutocomplete to tell us this, but it's nice to give the feedback
    // straight away on the modals that don't use autosubmit.
    const place = this.autocomplete?.getPlace();
    if (!place?.address_components || !place?.types) {
      this.reportNoResults();
      return;
    }

    // We mark whether the customer explicitly chose from the autocomplete menu, as opposed to us taking the first result
    this.specificAddressChosen ??= true;

    if (this.data.get("autosubmit") !== "false") {
      this.submit();
    }

    if (this.hasChangeAddressButtonTarget) {
      this.changeAddressButtonTarget.disabled = false;
    }
  }

  async checkNewAddress(event: Event): Promise<void> {
    event.preventDefault();
    this.addressErrorTextTarget.innerText = "";

    const result = await this.resolveAutocomplete();
    if (!result) return;

    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;
    if (metaElement === undefined) {
      throw "Missing CSRF meta tag";
    }
    const csrfToken = metaElement?.content;

    const response = await fetch("orders/validate_new_address", {
      method: "POST",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify(result)
    });

    const json = await response.json();

    if (response.status == 200) {
      await this.submitResult(result);
    } else if (response.status == 300) {
      this.modalContentTarget.innerText = json['confirmationText'];
      this.notServicedSectionTarget.classList.remove("is-completely-hidden");
      this.addressInputSectionTarget.classList.add("is-completely-hidden");
    } else {
      this.reportError(json['error'], false);
    }
  }

  closeModal() {
    this.modalContentTarget.innerText = "";
    this.modalTarget.classList.remove("is-active");
    this.fieldTarget.value = ""
    this.changeAddressButtonTarget.disabled = true;
    this.notServicedSectionTarget.classList.add("is-completely-hidden");
    this.addressInputSectionTarget.classList.remove("is-completely-hidden");
  }

  showModal(){
    this.modalTarget.classList.add("is-active");
  }

  async updateAddressAndRedirect(e: Event): Promise<void> {
    e.preventDefault()
    await this.submit()
    window.location.href = "/"
  }

  async buttonPressed(event: Event): Promise<void> {
    event.preventDefault();
    this.submit();
  }

  async submitResult(addressParams?: AddressParams): Promise<void> {
    if (this.hasApplyButtonTarget) {
      this.applyButtonTarget.classList.add("is-loading");
      this.applyButtonTarget.disabled = true;
    }

    // Grab the rest of the form
    const formData = new FormData(this.formTarget);
    const params = Object.fromEntries(formData.entries());

    // Add the selected address details, if any
    if (addressParams) Object.assign(params, addressParams);

    // This param isn't really very clearly named any more, but it's only used for debugging.
    if (this.specificAddressChosen) params.autocompleted_address = "true";

    const csrfToken = (document.querySelector("meta[name='csrf-token']") as HTMLMetaElement)?.content;

    const response = await fetch(this.formTarget.action, {
      method: "POST",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify(params)
    });

    const json = await response.json();

    if (json.error) {
      this.reportError(json.error, json.support_link);
    } else {
      window.location.reload();
    }
  }

  async submit(): Promise<void> {
    if (!this.formTarget.checkValidity()) {
      // Blank input fields, bail out
      this.formTarget.reportValidity();
    } else if (this.fieldTarget.value == "") {
      // No address change, but submit the rest of the form, for those that have multiple functions.
      await this.submitResult(undefined);
    } else if (!this.autocomplete?.getPlace() || this.specificAddressChosen === undefined) {
      // If there's been typing in the autocomplete input that hasn't already been resolved by an
      // autocomplete selection, try to select an entry.
      //
      // Clicking the button will have removed focus from the autocomplete, making it remove its
      // list and stop listening to keydown events. Simulate a focus event to bring the list back,
      // a down-arrow to move to the first element in the list, then an Enter event to choose it.
      this.specificAddressChosen = false;
      this.fieldTarget.dispatchEvent(new UIEvent("focus", { view: window, bubbles: true, cancelable: true }));
      this.fieldTarget.dispatchEvent(new KeyboardEvent("keydown", { view: window, bubbles: true, cancelable: true, which: 40, keyCode: 40 }));
      this.fieldTarget.dispatchEvent(new KeyboardEvent("keydown", { view: window, bubbles: true, cancelable: true, which: 13, keyCode: 13, key: "Enter" }));
    } else {
      // Autocomplete entry selected, check and tweak it if necessary
      const result = await this.resolveAutocomplete();
      if (result) await this.submitResult(result);
    }
  }

  inputChanged(): void {
    // Capture the input for later debugging
    this.originalInput = this.fieldTarget.value;

    // And clear the autocompleted selection flag until inputChanged is called again
    this.specificAddressChosen = undefined;

    // Disable submit for the change address form until the place gets selected so we don't submit stale values
    if (this.hasChangeAddressButtonTarget) {
      this.changeAddressButtonTarget.disabled = true;
    }
  }

  private async resolveAutocomplete(): Promise<AddressParams | null> {
    const addressResolver = new GoogleAutocompleteResolver();
    const result = await addressResolver.resolveFromAutocomplete(this.fieldTarget.value, this.autocomplete?.getPlace());
    if (!result) {
      this.reportNoResults();
      return null;
    } else {
      return result;
    }
  }

  reportNoResults() {
    this.reportError(`Sorry, we're having trouble finding an exact location for ${this.fieldTarget.value}. Please enter a complete street address.`, false);
  }

  reportError(error: string, showContactButton: boolean): void {
    this.addressErrorTextTarget.innerText = error;
    this.addressErrorTextTarget.parentElement?.classList?.remove("is-completely-hidden");
    this.addressErrorTextTarget.parentElement?.classList?.add("has-error");

    if (this.hasAddressErrorButtonTarget) {
      if (showContactButton) {
        this.addressErrorButtonTarget.classList.remove("is-completely-hidden");
      } else {
        this.addressErrorButtonTarget.classList.add("is-completely-hidden");
      }
    }

    if (this.hasApplyButtonTarget) {
      this.applyButtonTarget.classList.remove("is-loading");
      this.applyButtonTarget.disabled = false;
    }
  }

  keydown(event : KeyboardEvent): void {
    if (event.key == "Enter") {
      // Stop the default form submission action, letting the Autocompleter code call placeChanged to submit
      event.preventDefault();

      if (!document.querySelector(".pac-item-selected")) {
        // No item's highlighted in the autocomplete menu yet, so move onto the first one, then let
        // the autocomplete's keydown handler select it. Mark this as not explicitly selected, too.
        this.specificAddressChosen = false;
        this.fieldTarget.dispatchEvent(new KeyboardEvent("keydown", { view: window, bubbles: true, cancelable: true, which: 40, keyCode: 40 }));
      }
    }
  }
}
