/**
 * File generated by js-routes 2.2.5
 * Based on Rails 7.1.5 routes of Hangry::Application
 */
// eslint-disable-next-line
const __jsr = (
// eslint-disable-next-line
() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) ||
                (false &&
                    result === false)
                ? ""
                : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/drivers/:id/add(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addAdminDispatchDashboardDriverUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/drivers/:id/add(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addAdminDispatchDashboardDriverPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/chats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminChatsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"chats"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/chats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminChatsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"chats"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCustomerSubscriptionsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/customer_subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCustomerSubscriptionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/deliveries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDeliveriesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"deliveries"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/deliveries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDeliveriesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"deliveries"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/update_panic(.:format)
 * @param {any} areaId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDispatchDashboardUpdatePanicUrl = __jsr.r({"area_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"update_panic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/update_panic(.:format)
 * @param {any} areaId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDispatchDashboardUpdatePanicPath = __jsr.r({"area_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"update_panic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/drivers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDriverUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/drivers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDriverPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/map_blocks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminMapBlockUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"map_blocks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/map_blocks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminMapBlockPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"map_blocks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/map_blocks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminMapBlocksUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"map_blocks"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/map_blocks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminMapBlocksPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"map_blocks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/order_notes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminOrderNoteUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"order_notes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/order_notes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminOrderNotePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"order_notes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:order_id/order_restaurant_feedbacks(.:format)
 * @param {any} orderId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminOrderOrderRestaurantFeedbacksUrl = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"order_restaurant_feedbacks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:order_id/order_restaurant_feedbacks(.:format)
 * @param {any} orderId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminOrderOrderRestaurantFeedbacksPath = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"order_restaurant_feedbacks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminOrdersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminOrdersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/phones/check_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPhonesCheckUserUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"phones"],[2,[7,"/"],[2,[6,"check_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/phones/check_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPhonesCheckUserPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"phones"],[2,[7,"/"],[2,[6,"check_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/phones/update_connect_contact_attributes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPhonesUpdateConnectContactAttributesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"phones"],[2,[7,"/"],[2,[6,"update_connect_contact_attributes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/phones/update_connect_contact_attributes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPhonesUpdateConnectContactAttributesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"phones"],[2,[7,"/"],[2,[6,"update_connect_contact_attributes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/phones/update_routing_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPhonesUpdateRoutingProfileUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"phones"],[2,[7,"/"],[2,[6,"update_routing_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/phones/update_routing_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPhonesUpdateRoutingProfilePath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"phones"],[2,[7,"/"],[2,[6,"update_routing_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/storefronts/:storefront_id/images(.:format)
 * @param {any} storefrontId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminStorefrontImagesUrl = __jsr.r({"storefront_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"storefronts"],[2,[7,"/"],[2,[3,"storefront_id"],[2,[7,"/"],[2,[6,"images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/storefronts/:storefront_id/images(.:format)
 * @param {any} storefrontId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminStorefrontImagesPath = __jsr.r({"storefront_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"storefronts"],[2,[7,"/"],[2,[3,"storefront_id"],[2,[7,"/"],[2,[6,"images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/deliveries/:id/allocate(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const allocateAdminDispatchDashboardDeliveryUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"allocate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/deliveries/:id/allocate(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const allocateAdminDispatchDashboardDeliveryPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"allocate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/groups/:id/allocate(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const allocateAdminDispatchDashboardGroupUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"allocate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/groups/:id/allocate(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const allocateAdminDispatchDashboardGroupPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"allocate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/autocomplete/admins_without_role(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminAutocompleteAdminsWithoutRoleUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"autocomplete"],[2,[7,"/"],[2,[6,"admins_without_role"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/autocomplete/admins_without_role(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminAutocompleteAdminsWithoutRolePath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"autocomplete"],[2,[7,"/"],[2,[6,"admins_without_role"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/autocomplete/drivers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminAutocompleteDriversUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"autocomplete"],[2,[7,"/"],[2,[6,"drivers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/autocomplete/drivers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminAutocompleteDriversPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"autocomplete"],[2,[7,"/"],[2,[6,"drivers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/autocomplete/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminAutocompleteOrdersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"autocomplete"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/autocomplete/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminAutocompleteOrdersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"autocomplete"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/autocomplete/people(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminAutocompletePeopleUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"autocomplete"],[2,[7,"/"],[2,[6,"people"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/autocomplete/people(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminAutocompletePeoplePath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"autocomplete"],[2,[7,"/"],[2,[6,"people"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/autocomplete/restaurants(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminAutocompleteRestaurantsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"autocomplete"],[2,[7,"/"],[2,[6,"restaurants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/autocomplete/restaurants(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminAutocompleteRestaurantsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"autocomplete"],[2,[7,"/"],[2,[6,"restaurants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/chat/conversations/:view/:id(.:format)
 * @param {any} view
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminChatUrl = __jsr.r({"view":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"view"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/chat/conversations/:view/:id(.:format)
 * @param {any} view
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminChatPath = __jsr.r({"view":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"view"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/chat/auth_key(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminChatAuthKeyUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"auth_key"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/chat/auth_key(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminChatAuthKeyPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"auth_key"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/add_driver(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchAddDriverUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_driver"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/add_driver(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchAddDriverPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_driver"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/allocate_group(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchAllocateGroupUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"allocate_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/allocate_group(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchAllocateGroupPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"allocate_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/allocate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchAllocateOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"allocate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/allocate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchAllocateOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"allocate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/create_driver_note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchCreateDriverNoteUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_driver_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/create_driver_note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchCreateDriverNotePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_driver_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/create_restaurant_note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchCreateRestaurantNoteUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_restaurant_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/create_restaurant_note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchCreateRestaurantNotePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_restaurant_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchDashboardUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchDashboardPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/delay_order_sms(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchDelayOrderSmsUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delay_order_sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/delay_order_sms(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchDelayOrderSmsPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delay_order_sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/delay_prep(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchDelayPrepUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delay_prep"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/delay_prep(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchDelayPrepPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delay_prep"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/group(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchGroupOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/group(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchGroupOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/lock_group(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchLockGroupUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"lock_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/lock_group(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchLockGroupPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"lock_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/merge_groups(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchMergeGroupsUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"merge_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/merge_groups(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchMergeGroupsPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"merge_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/remove_driver(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchRemoveDriverUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_driver"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/remove_driver(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchRemoveDriverPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_driver"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/reorder_groups(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchReorderGroupsUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reorder_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/reorder_groups(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchReorderGroupsPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reorder_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/unlock_group(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchUnlockGroupUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/unlock_group(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchUnlockGroupPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/update_dash_note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchUpdateDashNoteUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_dash_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/update_dash_note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchUpdateDashNotePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_dash_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/update_panic(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchUpdatePanicUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_panic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/dispatch/dashboard/:id/update_panic(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminDispatchUpdatePanicPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_panic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/incident/report_incident(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminIncidentReportIncidentUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"incident"],[2,[7,"/"],[2,[6,"report_incident"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/admin/incident/report_incident(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminIncidentReportIncidentPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"incident"],[2,[7,"/"],[2,[6,"report_incident"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/order_notes/:id/assign(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assignAdminOrderNoteUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"order_notes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/order_notes/:id/assign(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assignAdminOrderNotePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"order_notes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/attach_images(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attachImagesAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"attach_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/attach_images(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attachImagesAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"attach_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/cancel_return(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelReturnAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel_return"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/cancel_return(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelReturnAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel_return"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/cancelled(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelledAdminOrdersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"cancelled"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/cancelled(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelledAdminOrdersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"cancelled"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mates_rates/change_payment_method(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const changePaymentMethodCustomerSubscriptionsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mates_rates"],[2,[7,"/"],[2,[6,"change_payment_method"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /mates_rates/change_payment_method(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const changePaymentMethodCustomerSubscriptionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mates_rates"],[2,[7,"/"],[2,[6,"change_payment_method"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/groups/compact(.:format)
 * @param {any} areaId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compactAdminDispatchDashboardGroupsUrl = __jsr.r({"area_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[6,"compact"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/groups/compact(.:format)
 * @param {any} areaId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compactAdminDispatchDashboardGroupsPath = __jsr.r({"area_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[6,"compact"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/complete_return(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const completeReturnAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete_return"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/complete_return(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const completeReturnAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete_return"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /checkout/:order_id/confirm_for_payment(.:format)
 * @param {any} orderId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmForPaymentCheckoutUrl = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"confirm_for_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /checkout/:order_id/confirm_for_payment(.:format)
 * @param {any} orderId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmForPaymentCheckoutPath = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"confirm_for_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /gift_vouchers/:id/confirm_for_payment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmForPaymentGiftVoucherUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"gift_vouchers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm_for_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /gift_vouchers/:id/confirm_for_payment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmForPaymentGiftVoucherPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"gift_vouchers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm_for_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/create_coupon(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createCouponAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_coupon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/create_coupon(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createCouponAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_coupon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/drivers/:id/create_note(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createNoteAdminDispatchDashboardDriverUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/drivers/:id/create_note(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createNoteAdminDispatchDashboardDriverPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/stores/:id/create_note(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createNoteAdminDispatchDashboardStoreUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"stores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/stores/:id/create_note(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createNoteAdminDispatchDashboardStorePath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"stores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/create_note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createNoteAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/create_note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createNoteAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/create_refund(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createRefundAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_refund"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/create_refund(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createRefundAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_refund"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/create_refund_from_items(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createRefundFromItemsAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_refund_from_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/create_refund_from_items(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createRefundFromItemsAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_refund_from_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/create_remake(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createRemakeAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_remake"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/create_remake(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createRemakeAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_remake"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/create_restaurant_adjustment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createRestaurantAdjustmentAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_restaurant_adjustment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/create_restaurant_adjustment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createRestaurantAdjustmentAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_restaurant_adjustment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mates_rates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customerSubscriptionsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mates_rates"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /mates_rates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customerSubscriptionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mates_rates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/customer_subscriptions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customerSubscriptionsAdminUserUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"customer_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/users/:id/customer_subscriptions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customerSubscriptionsAdminUserPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"customer_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mates_rates/cancel_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customerSubscriptionsCancelSubscriptionUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mates_rates"],[2,[7,"/"],[2,[6,"cancel_subscription"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /mates_rates/cancel_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customerSubscriptionsCancelSubscriptionPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mates_rates"],[2,[7,"/"],[2,[6,"cancel_subscription"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mates_rates/resume_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customerSubscriptionsResumeSubscriptionUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mates_rates"],[2,[7,"/"],[2,[6,"resume_subscription"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /mates_rates/resume_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customerSubscriptionsResumeSubscriptionPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mates_rates"],[2,[7,"/"],[2,[6,"resume_subscription"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/deliveries/:id/delay_prep(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delayPrepAdminDispatchDashboardDeliveryUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delay_prep"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/deliveries/:id/delay_prep(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delayPrepAdminDispatchDashboardDeliveryPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delay_prep"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/detach_image(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const detachImageAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"detach_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/detach_image(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const detachImageAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"detach_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/deliveries/:id/details(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const detailsAdminDispatchDashboardDeliveryUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/deliveries/:id/details(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const detailsAdminDispatchDashboardDeliveryPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/driver_arrived_email(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const driverArrivedEmailAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"driver_arrived_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/driver_arrived_email(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const driverArrivedEmailAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"driver_arrived_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/order_notes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminOrderNoteUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"order_notes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/order_notes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminOrderNotePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"order_notes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/email_late_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const emailLateOrdersAdminOrdersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"email_late_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/email_late_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const emailLateOrdersAdminOrdersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"email_late_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /partner/analytics/embed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const embedPartnerPortalAnalyticsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"partner"],[2,[7,"/"],[2,[6,"analytics"],[2,[7,"/"],[2,[6,"embed"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /partner/analytics/embed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const embedPartnerPortalAnalyticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"partner"],[2,[7,"/"],[2,[6,"analytics"],[2,[7,"/"],[2,[6,"embed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/map_blocks/find(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const findAdminMapBlocksUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"map_blocks"],[2,[7,"/"],[2,[6,"find"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/map_blocks/find(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const findAdminMapBlocksPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"map_blocks"],[2,[7,"/"],[2,[6,"find"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/force_acknowledge(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const forceAcknowledgeAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"force_acknowledge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/force_acknowledge(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const forceAcknowledgeAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"force_acknowledge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/deliveries/:id/group(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const groupAdminDispatchDashboardDeliveryUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/deliveries/:id/group(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const groupAdminDispatchDashboardDeliveryPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/initiate_return(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const initiateReturnAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"initiate_return"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/initiate_return(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const initiateReturnAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"initiate_return"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/late(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lateAdminOrdersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"late"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/late(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lateAdminOrdersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"late"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/groups/:id/lock(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lockAdminDispatchDashboardGroupUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/groups/:id/lock(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lockAdminDispatchDashboardGroupPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/groups/:id/merge(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mergeAdminDispatchDashboardGroupUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"merge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/groups/:id/merge(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mergeAdminDispatchDashboardGroupPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"merge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /new_look/confirm_for_payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newLookConfirmForPaymentUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"new_look"],[2,[7,"/"],[2,[6,"confirm_for_payment"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /new_look/confirm_for_payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newLookConfirmForPaymentPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"new_look"],[2,[7,"/"],[2,[6,"confirm_for_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /new_look/review_order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newLookReviewOrderUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"new_look"],[2,[7,"/"],[2,[6,"review_order"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /new_look/review_order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newLookReviewOrderPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"new_look"],[2,[7,"/"],[2,[6,"review_order"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/drivers/:id/remove(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const removeAdminDispatchDashboardDriverUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/drivers/:id/remove(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const removeAdminDispatchDashboardDriverPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/order_notes/:id/resolve(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resolveAdminOrderNoteUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"order_notes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resolve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/order_notes/:id/resolve(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resolveAdminOrderNotePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"order_notes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resolve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restaurantUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restaurantPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/orders/returned(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const returnedAdminOrdersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"returned"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/returned(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const returnedAdminOrdersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"returned"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /restaurants/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchRestaurantsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"restaurants"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /restaurants/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchRestaurantsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"restaurants"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/deliveries/:id/send_delay_message(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sendDelayMessageAdminDispatchDashboardDeliveryUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_delay_message"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/deliveries/:id/send_delay_message(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sendDelayMessageAdminDispatchDashboardDeliveryPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_delay_message"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /settings/saved_cards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settingsSavedCardUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"saved_cards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /settings/saved_cards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settingsSavedCardPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"saved_cards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /settings/saved_cards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settingsSavedCardsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"saved_cards"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /settings/saved_cards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settingsSavedCardsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"saved_cards"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /imagery/store_attachment/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storeAttachmentImageryUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"imagery"],[2,[7,"/"],[2,[6,"store_attachment"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /imagery/store_attachment/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storeAttachmentImageryPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"imagery"],[2,[7,"/"],[2,[6,"store_attachment"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:id/cart(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storeCartUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cart"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /:id/cart(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storeCartPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cart"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /:id/categories(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storeCategoriesUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /:id/categories(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storeCategoriesPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /:id/search(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storeSearchUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /:id/search(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storeSearchPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /storefronts/:storefront_id/carts(.:format)
 * @param {any} storefrontId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storefrontCartsUrl = __jsr.r({"storefront_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"storefronts"],[2,[7,"/"],[2,[3,"storefront_id"],[2,[7,"/"],[2,[6,"carts"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /storefronts/:storefront_id/carts(.:format)
 * @param {any} storefrontId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storefrontCartsPath = __jsr.r({"storefront_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"storefronts"],[2,[7,"/"],[2,[3,"storefront_id"],[2,[7,"/"],[2,[6,"carts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /storefronts/:storefront_id/deals(.:format)
 * @param {any} storefrontId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storefrontDealsUrl = __jsr.r({"storefront_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"storefronts"],[2,[7,"/"],[2,[3,"storefront_id"],[2,[7,"/"],[2,[6,"deals"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /storefronts/:storefront_id/deals(.:format)
 * @param {any} storefrontId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storefrontDealsPath = __jsr.r({"storefront_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"storefronts"],[2,[7,"/"],[2,[3,"storefront_id"],[2,[7,"/"],[2,[6,"deals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /storefronts/:storefront_id/images(.:format)
 * @param {any} storefrontId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storefrontImagesUrl = __jsr.r({"storefront_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"storefronts"],[2,[7,"/"],[2,[3,"storefront_id"],[2,[7,"/"],[2,[6,"images"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /storefronts/:storefront_id/images(.:format)
 * @param {any} storefrontId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storefrontImagesPath = __jsr.r({"storefront_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"storefronts"],[2,[7,"/"],[2,[3,"storefront_id"],[2,[7,"/"],[2,[6,"images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /storefronts/past_stores(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storefrontsPastStoresUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"storefronts"],[2,[7,"/"],[2,[6,"past_stores"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /storefronts/past_stores(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const storefrontsPastStoresPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"storefronts"],[2,[7,"/"],[2,[6,"past_stores"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/map_blocks/suburbs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const suburbsAdminMapBlocksUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"map_blocks"],[2,[7,"/"],[2,[6,"suburbs"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/map_blocks/suburbs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const suburbsAdminMapBlocksPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"map_blocks"],[2,[7,"/"],[2,[6,"suburbs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/groups/:id/unlock(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlockAdminDispatchDashboardGroupUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/groups/:id/unlock(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlockAdminDispatchDashboardGroupPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/deliveries/:id/update_dash_note(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateDashNoteAdminDispatchDashboardDeliveryUrl = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_dash_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dispatch_dashboard/:area_id/deliveries/:id/update_dash_note(.:format)
 * @param {any} areaId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateDashNoteAdminDispatchDashboardDeliveryPath = __jsr.r({"area_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispatch_dashboard"],[2,[7,"/"],[2,[3,"area_id"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_dash_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/areas/:id/update_dispatcher(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateDispatcherAdminAreaUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_dispatcher"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/areas/:id/update_dispatcher(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateDispatcherAdminAreaPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_dispatcher"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/orders/:id/update_resolution(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateResolutionAdminOrderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_resolution"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/orders/:id/update_resolution(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateResolutionAdminOrderPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_resolution"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

