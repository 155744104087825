import React from "react";

import { DropdownButton } from "./dropdown_button";

const RestaurantPrepButton = () : React.JSX.Element => {
  const button = <>
    <span className="icon">
      <i className="fas fa-check-circle" aria-hidden="true"></i>
    </span>

    <span>Prep</span>

    <span className="icon">
      <i className="fas fa-chevron-down" aria-hidden="true"></i>
    </span>
  </>;

  const menuContent = <>
    <a className="dropdown-item">
      Content
    </a>
  </>;

  return <DropdownButton
    button={button}
    menuContent={menuContent}
    buttonClasses={{}}
  />;

}

export { RestaurantPrepButton }
