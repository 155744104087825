import { Controller } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader";
import { Client, ChatType } from "src/admin/chat/client";
import { ChatComponent } from "src/admin/chat/chat";
import DispatchApiClient from "src/admin/dispatch/dispatch_api_client";
import { StoreInfo } from "src/admin/dispatch/driver_tracker_map";
import { createRoot } from 'react-dom/client';
import React from "react";

declare global {
  interface Document {
    originalTitle: typeof document.title;
  }
}

export default class NewChatController extends Controller {
  static targets = ["content", "map", "lastUpdateTime"];

  declare contentTarget: HTMLDivElement;
  declare mapTarget: HTMLElement;
  declare hasMapTarget: boolean;
  declare lastUpdateTimeTarget: HTMLElement;
  declare hasLastUpdateTimeTarget: boolean;

  chatClient?: Client;
  dispatchApiClients: Map<number, DispatchApiClient> = new Map();
  stores?: StoreInfo[];

  async connect(): Promise<void> {
    await this.setupFromData();

    document.originalTitle = document.title;

    const urlParams = new URLSearchParams(window.location.search);
    const initConversationId = parseInt(urlParams.get("focus") || "") || null;

    if (!this.chatClient) {
      this.showError(`Setup failed, missing client data`);
    }

    const root = createRoot(this.contentTarget);
    root.render(
      React.createElement(ChatComponent, {
        client: this.chatClient,
        dispatchApiClients: this.dispatchApiClients,
        stores: this.stores,
        initConversationId
      })
    );
  }

  private async setupFromData(): Promise<void> {
    const uuid = this.data.get("uuid");
    if (uuid === null) this.showError("Missing UUID");

    const subscribe: string | null = this.data.get("subscribe");
    if (subscribe === null) this.showError("Missing subscribe key");

    const publish = this.data.get("publish");
    if (publish === null) this.showError("Missing publish key");

    const chatIdString = this.data.get("area-ids");
    if (chatIdString === null) this.showError("Missing chat setup data (id)");

    const chatIds = chatIdString.split(",").map((s) => parseInt(s));

    let chatType = null;
    switch (this.data.get("view")) {
      case "dispatcher":
        chatType = ChatType.Dispatcher;
        break;

      case "manager":
        chatType = ChatType.Manager;
        break;

      default:
        this.showError(`Missing chat setup data (type: ${chatType})`);
    }
    const earliestUnreadsFrom = this.data.get("earliest-unreads-from")

    const railsEnv = this.data.get("rails-env");
    if (railsEnv == null) this.showError("Missing rails env data");

    const loader = new Loader({ apiKey: this.data.get("maps-key") ?? "", region: "NZ", language: "en" });
    await loader.load();

    this.stores = JSON.parse(this.data.get("stores") ?? "");

    this.chatClient = new Client(subscribe, publish, uuid, chatType, chatIds, railsEnv, earliestUnreadsFrom);

    const csrfToken = (document.querySelector("meta[name='csrf-token']") as HTMLMetaElement)?.content;
    if (!csrfToken) this.showError("Missing CSRF meta tag value");

    chatIds.forEach((cid) => {
      const dispatchApiClient = new DispatchApiClient(cid, csrfToken);
      dispatchApiClient.start();
      this.dispatchApiClients.set(cid, dispatchApiClient);
    });

    const reloadInSeconds = this.data.get("reload-in-seconds");
    if (reloadInSeconds != undefined) {
      window.setTimeout(() => window.location.reload(), parseInt(reloadInSeconds)*1000);
    }
  }

  private showError(msg: string): never {
    console.error("A runtime error has been raised:", msg);
    alert("An error has occured, sorry!");
    throw msg;
  }
}
