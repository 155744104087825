import { Controller } from "@hotwired/stimulus";
import autoComplete from "@tarekraafat/autocomplete.js";

type autoComplete = {
  start: () => void;
};

export default class AutocompleteFieldController extends Controller {
  static targets = ["input", "hidden"];

  autocompletor?: autoComplete;
  setupAutocompleteCallback?: EventListenerOrEventListenerObject;

  declare inputTarget : HTMLInputElement;
  declare hiddenTarget : HTMLInputElement;
  declare hasHiddenTarget : boolean;

  connect() : void {
    this.setupAutocomplete();

    this.setupAutocompleteCallback = this.setupAutocomplete.bind(this);
    window.addEventListener("turbo:morph", this.setupAutocompleteCallback);
  }

  disconnect() : void {
    if (this.setupAutocompleteCallback)
      window.removeEventListener("turbo:morph", this.setupAutocompleteCallback);
  }

  setupAutocomplete() : void {
    const dataUrl = this.data.get("data-url");
    if (dataUrl === null) {
      console.error("Can't setup autocomplete without a data-url");
      return;
    }

    const noThreshold = this.data.get("threshold") === "0";

    this.autocompletor = new autoComplete({
      selector: () => this.inputTarget,
      data: {
        src: async (query: string) => {
          const source = await fetch(`${dataUrl}${dataUrl.includes('?') ? '&' : '?'}q=${encodeURIComponent(query)}`);
          const data = await source.json();

          return data;
        },
        keys: ["label"]
      },
      resultsList: {
        class: "autocomplete-result-list",
      },
      resultItem: {
        highlight: {
          render: true
        }
      },
      debounce: 300,
      events: {
        input: {
          focus: () => {
            if (noThreshold && this.autocompletor != null) {
              this.autocompletor.start();
            }
          },
          selection: (ev: CustomEvent) => {
            const selection = ev.detail.selection.value;
            const evt = document.createEvent("HTMLEvents");
            evt.initEvent("change", true, true);

            if (this.hasHiddenTarget && this.hiddenTarget) {
              this.hiddenTarget.value = selection.value;

              this.hiddenTarget.dispatchEvent(evt);
            }

            this.inputTarget.value = selection.label;
            this.inputTarget.dispatchEvent(evt);
          }
        }
      },
      ...(noThreshold) && { threshold: 0 }
    });
  }
}
