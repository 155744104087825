import { createContext, useContext } from "react";
import { Copyable } from "../../common_util";
import { Availability, Catalog, FulfillmentMethod, OutOfStockList, Product, Section, SelectionGroup } from "./catalog";
import { Temporal } from "@js-temporal/polyfill";
import { activeOrUpcomingAvailabilitiesToday, earliestStartingAvailability } from "src/storefront/availability_helpers";
import { Cart, CartItem } from "./cart";
import { StorefrontExperience } from "src/storefront/types/storefront_experience";
import { CartItemEventItemDefaults } from "src/analytics/cart_item_event_serializer";

export type ProductDealDetails = {
  productKeys: Array<string>;
  productDealText: string;
}

export type Deal = {
  id: number;
  title: string;
  description: string;
  termsAndConditions?: string;
  productDetails?: ProductDealDetails;
}

class StoreInfo {
  readonly cartFulfillmentTimeEstimate: string;
  readonly fulfillmentTimeOptions: Array<Temporal.ZonedDateTime>;

  constructor({
    cartFulfillmentTimeEstimate,
    fulfillmentTimeOptions,
  } : {
    cartFulfillmentTimeEstimate: string;
    fulfillmentTimeOptions: Array<Temporal.ZonedDateTime>;
  }) {
    this.cartFulfillmentTimeEstimate = cartFulfillmentTimeEstimate;
    this.fulfillmentTimeOptions = fulfillmentTimeOptions;
  }
}

class StorefrontConfig extends Copyable {
  readonly storefrontId: string;
  readonly storefrontName: string;
  readonly storefrontPermalink: string;
  readonly csrfToken: string;
  readonly fulfillmentMethod: FulfillmentMethod;
  readonly presentationStatus: string;
  readonly timeOffset: number;
  readonly preferredTime: Temporal.ZonedDateTime | null;
  readonly currentAddress: string;
  readonly allowsMealPreferences: boolean;
  readonly supportedFulfillmentMethods: Array<FulfillmentMethod>;
  readonly minimumSubtotal: number;
  readonly storeInfo: StoreInfo;
  readonly usesNewBottomNav: boolean;
  readonly usesProductSearch: boolean;
  readonly usesSubcategoryDisplay: boolean;
  readonly showSubstitutionsDisclaimer: boolean;
  readonly availabilityCache: Map<string, boolean>;
  readonly priceFormatter: Intl.NumberFormat;
  readonly loadedAt: Temporal.Instant;
  readonly searchIndexName: string;
  readonly searchAppId: string;
  readonly searchPublicApiKey: string;
  readonly searchCustomerToken: string;
  readonly searchAuthenticatedUserToken?: string;
  readonly catalogUrl: string;
  readonly outageUrl?: string;
  readonly imageBaseUrl: string;
  readonly hideAgeRestrictedItems: boolean;
  readonly storefrontExperience: StorefrontExperience
  readonly defaultItemsEventAttributes: CartItemEventItemDefaults

  constructor(args: {
      storefrontId: string;
      storefrontName: string;
      storefrontPermalink: string;
      csrfToken: string;
      fulfillmentMethod: FulfillmentMethod;
      presentationStatus: string;
      timeOffset: number;
      preferredTime: Temporal.ZonedDateTime | null;
      currentAddress: string;
      allowsMealPreferences: boolean;
      supportedFulfillmentMethods: Array<FulfillmentMethod>;
      minimumSubtotal: number;
      storeInfo: StoreInfo;
      usesNewBottomNav?: boolean;
      usesProductSearch?: boolean;
      usesSubcategoryDisplay?: boolean;
      showSubstitutionsDisclaimer?: boolean;
      priceFormatter: Intl.NumberFormat;
      searchIndexName: string;
      searchAppId: string;
      searchPublicApiKey: string;
      searchCustomerToken: string;
      searchAuthenticatedUserToken?: string;
      catalogUrl: string;
      outageUrl?: string;
      imageBaseUrl?: string;
      hideAgeRestrictedItems?: boolean;
      storefrontExperience: StorefrontExperience;
      defaultItemsEventAttributes: CartItemEventItemDefaults;
    }) {
    super();

    this.loadedAt = Temporal.Now.instant();

    this.storefrontId = args.storefrontId;
    this.storefrontName = args.storefrontName;
    this.storefrontPermalink = args.storefrontPermalink;
    this.csrfToken = args.csrfToken;
    this.fulfillmentMethod = args.fulfillmentMethod;
    this.presentationStatus = args.presentationStatus;
    this.timeOffset = args.timeOffset;
    this.preferredTime = args.preferredTime;
    this.currentAddress = args.currentAddress;
    this.allowsMealPreferences = args.allowsMealPreferences;
    this.supportedFulfillmentMethods = args.supportedFulfillmentMethods;
    this.minimumSubtotal = args.minimumSubtotal;
    this.storeInfo = args.storeInfo;
    this.usesNewBottomNav = args.usesNewBottomNav || false;
    this.usesProductSearch = args.usesProductSearch || false;
    this.usesSubcategoryDisplay = args.usesSubcategoryDisplay || false;
    this.showSubstitutionsDisclaimer = args.showSubstitutionsDisclaimer || false;
    this.availabilityCache = new Map<string, boolean>;
    this.priceFormatter = args.priceFormatter;
    this.searchIndexName = args.searchIndexName;
    this.searchAppId = args.searchAppId;
    this.searchPublicApiKey = args.searchPublicApiKey;
    this.searchCustomerToken = args.searchCustomerToken;
    this.searchAuthenticatedUserToken = args.searchAuthenticatedUserToken;
    this.catalogUrl = args.catalogUrl;
    this.outageUrl = args.outageUrl;
    this.imageBaseUrl = args.imageBaseUrl || (new URL('/product_images', new URL(args.catalogUrl, window.location.href)).toString());
    this.hideAgeRestrictedItems = args.hideAgeRestrictedItems || false;
    this.storefrontExperience = args.storefrontExperience;
    this.defaultItemsEventAttributes = args.defaultItemsEventAttributes;
  }

  preferredOrNextTime() : Temporal.ZonedDateTime | null {
    return (this.preferredTime || this.storeInfo.fulfillmentTimeOptions[0]);
  }

  timePrioritisedSections(sections : Section[]): Section[] {
    const preferred = this.preferredOrNextTime();

    const hasApplicableUnendedAvailability = (availabilities : Availability[]): boolean => {
      if (!preferred)
        return false;

      const preferredPickupTime = preferred.subtract({ minutes: this.timeOffset })

      return !!availabilities.find((av : Availability) => {
        const beforeEnd = Temporal.ZonedDateTime.compare(preferredPickupTime, preferredPickupTime.withPlainTime(av.endTime)) <= 0
        return av.wday == preferredPickupTime.dayOfWeek % 7 && beforeEnd
      })
    }

    const upcomingOrActiveSections = sections.filter((section: Section) => {
      return hasApplicableUnendedAvailability(section.availabilities);
    });

    const pastOrExcludedSections = sections.filter((section: Section) => {
      return !hasApplicableUnendedAvailability(section.availabilities);
    });

    let sortedUpcomingOrActiveSections = upcomingOrActiveSections;
    if (preferred) {
      const preferredPickupTime = preferred.subtract({ minutes: this.timeOffset })

      sortedUpcomingOrActiveSections = upcomingOrActiveSections.sort((a, b) => {
        return Temporal.PlainTime.compare(
          earliestStartingAvailability(activeOrUpcomingAvailabilitiesToday(a.availabilities, preferredPickupTime.toPlainTime(), preferredPickupTime.dayOfWeek % 7)).startTime,
          earliestStartingAvailability(activeOrUpcomingAvailabilitiesToday(b.availabilities, preferredPickupTime.toPlainTime(), preferredPickupTime.dayOfWeek % 7)).startTime,
        )
      });
    }

    return sortedUpcomingOrActiveSections.concat(pastOrExcludedSections);
  }
}

export enum RestrictionType {
    'age_r18' = 0,
}

class MissingStorefrontConfig extends Error {}
const StorefrontContext = createContext<StorefrontConfig | null>(null);
const useStorefrontConfig = (): StorefrontConfig => {
  const maybeConfig = useContext(StorefrontContext);

  if (!maybeConfig) {
    throw new MissingStorefrontConfig();
  }

  return maybeConfig;
}

class MissingModalContext extends Error {}
type OpenModalFunction = (product: Product, updateHistory: boolean, existingCartItem?: CartItem) => void;
const ModalContext = createContext<OpenModalFunction | null>(null);
const useModal = (): OpenModalFunction => {
  const maybeMethod = useContext(ModalContext);

  if (!maybeMethod) {
    throw new MissingModalContext();
  }

  return maybeMethod;
}

const CartContext = createContext<Cart | null>(null);
const useCart = (): Cart | null => {
  return useContext(CartContext);
}

class MissingCatalogData extends Error {}

class StorefrontHelper {
  catalog: Catalog;
  outOfStockList: OutOfStockList;
  imageBaseUrl: string;
  deals: Deal[];
  dealsByKey: { [key: string]: string[] };

  constructor({
    catalog, imageBaseUrl, outOfStockList, deals
  }: {
      catalog: Catalog;
      imageBaseUrl: string;
      outOfStockList: OutOfStockList;
      deals: Deal[];
  }) {
    this.catalog = catalog;
    this.imageBaseUrl = imageBaseUrl;
    this.outOfStockList = outOfStockList;
    this.deals = deals;

    this.dealsByKey = this.deals.reduce<{ [key: string]: string[] }>((acc, deal) => {
      const productDetails = deal.productDetails;
      if (productDetails) {
        productDetails.productKeys.forEach((key) => {
          acc[key] ??= [];
          acc[key].push(productDetails.productDealText);
        });
      }

      return acc;
    }, {});
  }

  // By defining these methods as arrow expressions instead of class methods,
  // we get ES6 block this-capture semantics. If these were just defined as
  // class methods, callers would not be able to use destructuring assignment
  // out of this object.
  isOrderable = (product: Product): boolean => {
    if (this.outOfStockList.isProductInOutage(product))
      return false;

    if (product.kind === "leaf")
      return true;

    const requiredSelectionGroups = product.selectionGroups.filter((sg) => sg.isRequired());
    return requiredSelectionGroups.every((sg) => this.isFulfillable(sg));
  }

  isFulfillable = (selectionGroup: SelectionGroup): boolean => {
    const fulfillableProducts = selectionGroup.products.filter((product) => this.isOrderable(product));
    const productMaxSum = fulfillableProducts.reduce((c, { maxQuantity }) => c + (maxQuantity ?? Number.POSITIVE_INFINITY), 0)

    return productMaxSum >= selectionGroup.minSelections;

  }

  hasImage = (product: Product): boolean => (product.images.length > 0);
  getPrimaryImageUrl = (product: Product): string | undefined => (product.images.length > 0 ? `${this.imageBaseUrl}/${product.images[0]}` : undefined);
  getImageUrls = (product: Product): string[] => product.images.map((imageId) => `${this.imageBaseUrl}/${imageId}`);

  isInFulfillableTimeRange = (product: Product): boolean => {
    const config = useStorefrontConfig();
    return this.catalog.isInFulfillableTimeRange(config, product);
  }

  dealDescriptionForProduct = (product: Product): string | undefined => {
    return (this.dealsByKey[product.key] || [])[0];
  }
}

const StorefrontHelperContext = createContext<StorefrontHelper | undefined>(undefined);
const useStorefrontHelper = (): StorefrontHelper => {
  const maybeData = useContext(StorefrontHelperContext);

  if (!maybeData) {
    throw new MissingCatalogData("StorefrontHelper isn't available!");
  }

  return maybeData;
}

const CategoryObserverContext = createContext<IntersectionObserver | null>(null);
const useCategoryObserver = (): IntersectionObserver | null => {
  return useContext(CategoryObserverContext)
}

export { StorefrontConfig, StoreInfo, StorefrontContext, useStorefrontConfig,
  ModalContext, useModal, CartContext, useCart,
  StorefrontHelperContext, useStorefrontHelper, StorefrontHelper,
  CategoryObserverContext, useCategoryObserver,
};
