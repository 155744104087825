import { Controller } from "@hotwired/stimulus";
import { autocomplete } from '@algolia/autocomplete-js';
import { BaseItem, AutocompleteState } from "@algolia/autocomplete-core";

const DEBOUNCE_MS = 200;

export default class AutocompleteJumpController extends Controller {
  static targets = ["container"];

  declare containerTarget : HTMLDivElement;

  setupAutocompleteCallback?: EventListenerOrEventListenerObject;
  autocompleteApi?: { setQuery: (query: string) => void; destroy: () => void };
  debounceTimer?: number;

  connect(): void {
    this.setupAutocomplete();

    this.setupAutocompleteCallback = this.setupAutocomplete.bind(this);
    window.addEventListener("turbo:morph", this.setupAutocompleteCallback);
  }

  disconnect(): void {
    if (this.setupAutocompleteCallback) {
      this.autocompleteApi?.destroy();
      window.removeEventListener("turbo:morph", this.setupAutocompleteCallback);
    }
  }

  setupAutocomplete(): void {
    const dataUrl = this.data.get("data-url");
    const pattern = this.data.get("redirect-pattern");
    const minLength = Number(this.data.get("min-length") ?? "0");
    if (dataUrl === null || pattern === null) {
      console.error("Can't setup autocomplete without a data-url and pattern");
      return;
    }

    const clearInput = () => this.autocompleteApi?.setQuery("");

    const fetchResults = async (query: string): Promise<BaseItem[]> => {
      const source = await fetch(`${dataUrl}${dataUrl.includes('?') ? '&' : '?'}q=${encodeURIComponent(query)}`);
      const data = await source.json();
      const matches = data.filter(({ label }: { label: string }) => label.toLowerCase().includes(query.toLowerCase()));
      return matches;
    };

    const debouncedFetchResults = (query: string): Promise<BaseItem[]> => {
      if (this.debounceTimer) window.clearTimeout(this.debounceTimer);
      return new Promise((resolve) => {
        this.debounceTimer = window.setTimeout(() => fetchResults(query).then((results) => resolve(results)), DEBOUNCE_MS);
      });
    }

    this.autocompleteApi = autocomplete({
      container: this.containerTarget,
      classNames: {
        form: "input",
        item: "p-2",
      },
      placeholder: this.data.get("placeholder") ?? "",
      autoFocus: true,
      defaultActiveItemId: 0,
      shouldPanelOpen({ state } : { state: AutocompleteState<BaseItem> }): boolean {
        return (state.query.length >= minLength);
      },
      getSources() {
        return [{
          sourceId: "autocomplete-data-url", // arbitrary
          getItems({ query } : { query: string }): Promise<BaseItem[]> {
            if (query.length < minLength) return Promise.resolve([]);
            return debouncedFetchResults(query);
          },
          getItemUrl({ item }) {
            return pattern.replace(/\{\}/, item.value as string);
          },
          templates: {
            item({ item, html }) {
              if (item.desc !== undefined) {
                return html`<div>${item.label}</div><div class="description">${item.desc}</div>`;
              } else {
                return html`<div>${item.label}</div>`;
              }
            },
            noResults({ state, html }) {
              return html`Sorry, no matches for "${state.query}" found`;
            },
          },
          onSelect({ event, itemUrl }) {
            if (event && itemUrl) {
              // to match the built-in navigator onKeyDown behavior, which itself attempts to match up with standard link behavior
              // bizarrely, defining getItemUrl only turns on the default keyboard behavior, not default click behavior - see
              // https://github.com/algolia/autocomplete/discussions/629#discussioncomment-1020638
              if (event.metaKey || event.ctrlKey) {
                // New tab
                window.open(itemUrl, "_blank", "noopener")?.focus();
              } else if (event.shiftKey) {
                // New window
                window.open(itemUrl, "_blank", "noopener");
              } else if (!event.altKey) {
                // Normal navigate
                window.location.assign(itemUrl);
              }
              clearInput();
            }
          },
        }];
      },
    });
  }
}
