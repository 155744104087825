import { Controller } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader";
import { GoogleAutocompleteResolver } from "src/google_autocomplete_resolver";

export default class StoresController extends Controller {
  static targets = ["addressInput"]

  declare hasAddressInputTarget: boolean;
  declare addressInputTarget: HTMLInputElement;

  addressAutocomplete?: google.maps.places.Autocomplete;

  async connect(): Promise<void> {
    const mapsKey = this.data.get("maps-key");
    if (mapsKey && this.hasAddressInputTarget) {
      await this.loadMaps(mapsKey);
      this.initAutocomplete();
    }
  }

  private async loadMaps(mapsApiKey: string): Promise<void> {
    const loader = new Loader({ apiKey: mapsApiKey, region: "NZ", language: "en" });
    await loader.load();
    await google.maps.importLibrary("places");
  }

  private initAutocomplete(): void {
    if (this.hasAddressInputTarget && this.addressInputTarget) {
      this.addressAutocomplete = new google.maps.places.Autocomplete(this.addressInputTarget, {
        types: ['address'], // "return only geocoding results with a precise address"
        fields: ['address_components'],
        bounds: GoogleAutocompleteResolver.defaultBounds(),
        strictBounds: true,
      });
    }
  }
}
