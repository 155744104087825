import React, { useState } from "react";
import { useDraggable } from "@dnd-kit/core";

import { DeliveryComponent, MaplinkNode } from "./delivery";
import { DriverComponent } from "./driver";
import { Delivery, Driver, DeliveryGroup, ApiClientInterface, Dashboard } from "./model";
import { DriverSelect, DeliveryGroupSelect } from "./selects";

import { classNames } from "src/common_util";

const DraggableDeliveryNode = ({
  dashboard,
  delivery,
  apiClient,
}: {
  dashboard: Dashboard;
  delivery: Delivery;
  apiClient: ApiClientInterface;
}): React.JSX.Element => {
  const [dropping, setDropping] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorTimerId, setErrorTimerId] = useState(0);

  const handleDropping = () => {
    setDropping(true);
  }

  const handleDragFailure = () => {
    setDropping(false);
    const errorTimer = window.setTimeout(() => setHasError(false), 1500);
    setErrorTimerId(errorTimer);
    setHasError(true);
  }

  const unsetErrorTimer = () => {
    window.clearTimeout(errorTimerId);
    setErrorTimerId(0);
    setHasError(false);
  }

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform } = useDraggable({
    id: `delivery:${delivery.id}`,
    data: {
      id: delivery.id,
      type: "delivery",
      handleDropping,
      handleDragFailure,
      unsetErrorTimer
    },
    // Only allow dragging on orders that are either make-now or acknowledged by the restaurant, & not currently being dropped
    disabled: (!delivery.makeNowFeature && !delivery.allowAllocation && !delivery.allowGrouping) || dropping
  });

  const style = transform ? {
    opacity: 0
  } : undefined;

  const classes = classNames({
    "is-dragged-node": !!transform,
    "has-drag-error": hasError,
    "is-half-opacity": dropping
  });

  return (
    <div className={`card is-card-dashboard mb-2 ${classes}`} style={style} ref={setNodeRef}>
      <div className="card-content p-0">
        <DeliveryComponent
          dashboard={dashboard}
          defaultExpand={true}
          delivery={delivery}
          deliveringNow={false}
          apiClient={apiClient}
          listeners={listeners}
          attributes={attributes}
          setActivatorNodeRef={setActivatorNodeRef}
        />
      </div>
    </div>
  );
}

const UnallocatedDeliveryList = ({
  dashboard,
  unallocatedDeliveries,
  apiClient,
}: {
  dashboard: Dashboard;
  unallocatedDeliveries: Delivery[];
  apiClient: ApiClientInterface;
}): React.JSX.Element => {
  const unallocatedDeliveryNodes = unallocatedDeliveries.map(delivery => {
    return (
      <DraggableDeliveryNode
        dashboard={dashboard}
        delivery={delivery}
        apiClient={apiClient}
        key={delivery.id}
      />
    );
  })

  return (
    <>
      {unallocatedDeliveryNodes}
    </>
  );
}

const DeliveryGroupComponent = ({
  dashboard,
  deliveries,
  group,
  apiClient,
}: {
  dashboard: Dashboard;
  deliveries: Delivery[];
  group: DeliveryGroup;
  apiClient: ApiClientInterface;
}): React.JSX.Element => {
  const [dropping, setDropping] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorTimerId, setErrorTimerId] = useState(0);

  const handleDropping = () => {
    setDropping(true);
  }

  const handleDragFailure = () => {
    setDropping(false);
    const errorTimer = window.setTimeout(() => setHasError(false), 1500);
    setErrorTimerId(errorTimer);
    setHasError(true);
  }

  const unsetErrorTimer = () => {
    window.clearTimeout(errorTimerId);
    setErrorTimerId(0);
    setHasError(false);
  }

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform } = useDraggable({
    id: `group:${group.id}`,
    data: {
      id: group.id,
      type: "group",
      handleDropping,
      handleDragFailure,
      unsetErrorTimer
    },
    disabled: dropping
  });

  const style = transform ? {
    opacity: 0
  } : undefined;

  const classes = classNames({
    "is-dragged-node": !!transform,
    "has-drag-error": hasError,
    "is-half-opacity": dropping
  });

  const handleAllocateGroupToDriver = async (driverId: number) => {
    await dashboard.handleAllocateGroupToDriver(group.id, driverId, apiClient);
  }

  const handleMergeGroups = async (toGroupId: number) => {
    if (toGroupId == -2) return;
    await apiClient.mergeGroups(group.id, toGroupId);
  }

  const handleLockToggle = async () => {
    if (group.locked) {
      await apiClient.unlockGroup(group.id);
    } else {
      await apiClient.lockGroup(group.id);
    }
  }

  const filterDeliveryGroups = (groups: DeliveryGroup[], groupId: number): DeliveryGroup[] => {
    return groups.filter((ele) => ele.id != groupId);
  }

  const availableDrivers = dashboard.availableDriversForDeliveries(deliveries);

  const lockedIcon = group.locked ? "lock" : "lock-open";

  const maplink = deliveries.length >= 2 ?
    <div className="p-1">
      <MaplinkNode deliveries={deliveries} />
    </div>
    : <></>;

  const deliveryGroupDeliveries = deliveries.map(delivery => {
    return (
      <DeliveryComponent
        key={delivery.id}
        dashboard={dashboard}
        defaultExpand={false}
        delivery={delivery}
        deliveringNow={false}
        apiClient={apiClient}
      />
    )
  });

  const driverSelect = (availableDrivers.length > 0 && (deliveries.every((delivery) => delivery.allowAllocation))) ?
    <DriverSelect drivers={availableDrivers} allowUnallocate={false} onConfirm={handleAllocateGroupToDriver} /> : <></>

  return (
    <div className={`card is-card-dashboard group ${classes}`} style={style} ref={setNodeRef}>
      <header className="card-header" {...listeners} {...attributes} ref={setActivatorNodeRef}>
        <div className="card-header-title px-1 py-1">
          <span className="is-size-7 has-text-weight-medium p-2">
            {group.groupNumber}
          </span>
        </div>

        {maplink}

        <div className="card-header-icon px-3 py-1">
          <span onClick={handleLockToggle} className="icon is-small p-2"><i className={`fas fa-${lockedIcon}`}></i></span>
        </div>
      </header>
      <div className="card-content p-0">
        <div className="card-surface" {...listeners} {...attributes} ref={setActivatorNodeRef}>
          <div className="columns is-variable is-1 px-2 pt-2 mb-0">
            <div className="column is-half pr-2">
              <div className="mb-1 has-text-weight-medium">Assign</div>
              {driverSelect}
            </div>

            <div className="column is-half pl-2">
              <div className="mb-1 has-text-weight-medium">Group</div>
              <DeliveryGroupSelect
                groups={filterDeliveryGroups(dashboard.groups, group.id)}
                allowUngroup={true}
                isUnallocatedOrder={false}
                onConfirm={handleMergeGroups}
              />
            </div>
          </div>
        </div>
        {deliveryGroupDeliveries}
      </div>
    </div>
  );
}

const DriverList = ({
  dashboard,
  drivers,
  apiClient,
}: {
  dashboard: Dashboard;
  drivers: Driver[];
  apiClient: ApiClientInterface;
}): React.JSX.Element => {
  return (
    <div className="driver-list columns is-multiline is-variable is-2">
      {drivers.map(driver => {
        return (
          <DriverComponent
            dashboard={dashboard}
            driver={driver}
            key={driver.id}
            apiClient={apiClient}
          />
        );
      })}
    </div>
  );
}

export { UnallocatedDeliveryList, DeliveryGroupComponent, DriverList };
