import React, { useState, useCallback, useEffect, useRef } from "react";
import { classNames } from "src/common_util";

const DropdownButton = ({
  button,
  buttonClasses,
  menuContent,
}: {
  button: React.JSX.Element;
  menuContent: React.JSX.Element;
  buttonClasses: Record<string, boolean>;
}) : React.JSX.Element => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const classes = classNames({
    "dropdown": true,
    "is-right": true,
    "is-active": isExpanded,
  });

  const baseButtonClasses = classNames(Object.assign({
    "button": true,
    "is-active": isExpanded,
  }, buttonClasses));

  const globalClickHandler = useCallback((event: MouseEvent) => {
    if (bodyRef.current && bodyRef.current.contains(event.target as HTMLElement)) { return; }

    setIsExpanded(false);
  }, []);

  useEffect(() => {
    window.addEventListener("click", globalClickHandler);

    return () => {
      window.removeEventListener("click", globalClickHandler);
    };
  }, [globalClickHandler]);

  return (
    <div ref={bodyRef} className={classes}>
      <div className="dropdown-trigger" onClick={toggleExpanded}>
        <button className={baseButtonClasses} aria-haspopup="true" aria-controls="dropdown-menu">
          {button}
        </button>
      </div>

      <div className="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {menuContent}
        </div>
      </div>
    </div>
  );
}

export { DropdownButton }
